<template>
  <div class="">
    <OverlayBadge
      value="8"
      severity="warn"
      class="c-alert mr-4 ml-2 p-2 cursor-pointer hover:bg-primary-50 dark:hover:bg-stone-600 rounded"
      @click="toggleAlerts"
    >
      <span class="material-symbols-outlined !text-4xl text-primary dark:text-zinc-400" style="">notifications</span>
    </OverlayBadge>

    <TieredMenu id="alert-menu" ref="tieredMenuAlert" :model="alertItems" :popup="true">
      <template #item="{ item, props, hasSubmenu }">
        <a class="flex items-center" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ t(item.label as string) }}</span>
          <span
            v-if="item.shortcut"
            class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1"
            >{{ item.shortcut }}</span
          >
          <i v-if="hasSubmenu" class="pi pi-angle-right ml-auto"></i>
        </a>
      </template>
    </TieredMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { MenuItem } from "primevue/menuitem";
import { alertMenu } from "../menus/AlertMenu";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const alertItems = ref<Array<MenuItem>>([]);

const fetchAlertItems = () => {
  alertItems.value = alertMenu();
};

onMounted(fetchAlertItems);

const tieredMenuAlert = ref();

const toggleAlerts = (event: Event) => {
  tieredMenuAlert.value.toggle(event);
};
</script>

<style lang="scss" scoped>
:deep(.c-alert).p-overlaybadge .p-badge {
  top: 1.1rem;
  right: 1.1rem;
}
</style>

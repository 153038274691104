<template>
  <div class="c-layout">
    <div class="c-navbar">
      <Header
        :is-mobile="isMobile"
        :show-sidebar-mini="showSidebarMini"
        :show-mobile-menu="showMobileMenu"
        @on-toggle-sidebar-mini="onToggleSidebarMini"
        @on-toggle-mobile-menu="onToggleMobileMenu"
      />
    </div>

    <div v-if="isMobile" id="c-mobile-toolbar" class="c-mobile-toolbar">
      <MobileToolbar
        @on-toggle-mobile-menu="onToggleMobileMenu"
        @on-toggle-more="toggleMoreOptions"
        @on-toggle-search="onToggleSearch"
      />
    </div>
    <div
      v-show="moreOptionsVisible && isMobile"
      id="c-more-options-menu"
      class="c-mobile-toolbar c-more-options-toolbar"
    ></div>
  </div>
  <Teleport to="body">
    <SidebarMenu
      :show-sidebar-mini="showSidebarMini"
      :show-mobile-menu="showMobileMenu"
      @on-toggle-mobile-menu="onToggleMobileMenu"
    />
  </Teleport>
  <Teleport v-if="isMobile" to="body">
    <div
      class="c-sidebar-overlay"
      :class="[{ 'c-sidebar-mobile': isMobile }, { 'c-sidebar-overlay-active': showMobileMenu }]"
      @click="onToggleMobileMenu"
    ></div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Header from "./components/Header.vue";
import SidebarMenu from "./sidebar/SidebarMenu.vue";
import { useMobile } from "@/views/MobileService";
import { useBodyCss } from "@/views/BodyCssService";
import { useLocalStorage } from "@/views/LocalStorageService";
import MobileToolbar from "./components/MobileToolbar.vue";

const { addSidebarMiniCss, removeSidebarMiniCss, addShowMobileMenuCss, removeShowMobileMenuCss } = useBodyCss();
const { showSidebarMini } = useLocalStorage();

const { isMobile } = useMobile();

const showMobileMenu = ref(false);
const initMobileMenu = () => {
  showMobileMenu.value = false;
  removeShowMobileMenuCss();
};

initMobileMenu();

watch(
  () => isMobile.value,
  (mobile: boolean) => {
    if (mobile) {
      removeSidebarMiniCss();
    } else if (showSidebarMini.value) {
      addSidebarMiniCss();
    }

    removeShowMobileMenuCss();
  }
);

showSidebarMini.value ? addSidebarMiniCss() : removeSidebarMiniCss();

const onToggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value;
  showMobileMenu.value ? addShowMobileMenuCss() : removeShowMobileMenuCss();
};

const onToggleSidebarMini = () => {
  showSidebarMini.value = !showSidebarMini.value;
  showSidebarMini.value ? addSidebarMiniCss() : removeSidebarMiniCss();
};

const onToggleSearch = () => {
  document.getElementById("searchInput")?.focus();
};

const moreOptionsVisible = ref<boolean>(false);

const toggleMoreOptions = () => {
  moreOptionsVisible.value = !moreOptionsVisible.value;
};
</script>

<style lang="scss" scoped>
.c-layout {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: var(--navbar-box-shadow);
  z-index: 3;
}

.c-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition-duration: 0.2s;

  &.c-sidebar-mobile.c-sidebar-overlay-active {
    z-index: 3;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    @media (max-width: 687px) {
      height: 90vh;
    }
    @media (min-width: 687px) and (max-width: 992px) {
      height: 92vh;
    }
  }
}

@media (max-width: 992px) {
  :deep(.p-button.p-button-text) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.c-mobile-toolbar {
  display: flex;
  justify-content: space-around;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: #fff;
  padding: 0.3rem;
  box-shadow: 2px -3px 5px rgb(140 140 140 / 15%);

  :deep(.p-button.p-button-text) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;
  }

  @media (min-width: 687px) and (max-width: 992px) {
    height: 8vh;
  }
}

.c-mobile-toolbar-text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7rem;
}

.c-more-options-toolbar {
  position: fixed;
  bottom: 11vh;
  box-shadow: 2px -3px 5px #bfd1f6, 2px 3px 5px #bfd1f6;
  @media (min-width: 687px) and (max-width: 992px) {
    bottom: 9vh;
  }
}
</style>

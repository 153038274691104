<template>
  <Button class="p-button-text self-baseline" @click="emit('onToggleMobileMenu')">
    <i class="pi pi-bars"></i>
    <span class="c-mobile-toolbar-text"> Menu</span></Button
  >
  <Button class="p-button-text self-baseline" @click="emit('onToggleSearch')"
    ><i class="pi pi-search"></i>
    <span class="c-mobile-toolbar-text"> Search</span>
  </Button>

  <Button v-if="currentContext" class="p-button-text self-baseline" @click="emit('onToggleMore')"
    ><i class="pi pi-ellipsis-v"></i>
    <span class="c-mobile-toolbar-text"> More</span>
  </Button>

  <UserProfile v-if="!currentContext" v-show="showUserProfile" :is-mobile="true" />
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import UserProfile from "./UserProfile.vue";
import { useRoute } from "vue-router";

const emit = defineEmits<{
  (e: "onToggleMobileMenu"): void;
  (e: "onToggleSearch"): void;
  (e: "onToggleMore"): void;
}>();

const showUserProfile = ref<boolean>(true);
const route = useRoute();

const contexts = [
  { path: "/order", key: "order" },
  { path: "/warehouse", key: "warehouse" },
];

const currentContext = computed<string>(() => {
  const currentPath = route.path;
  const context = contexts.find((ctx) => currentPath.startsWith(ctx.path));
  return context ? context.key : "";
});
</script>
<style scoped lang="scss"></style>

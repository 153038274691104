<template>
  <div>
    <div class="flex justify-between">
      <p class="font-semibold text-lg text-zinc-800 dark:text-stone-400">
        {{ productType === "product" ? t("search.product", 2) : t("search.inventory", 1) }} ({{
          props.productResult?.totalHits
        }})
      </p>
      <Button
        :label="t('search.show-all')"
        class="p-button-link"
        :data-testid="`${productType}-show-all`"
        @click="$emit('goToPage', null, false)"
      ></Button>
    </div>
    <div
      v-for="product in props.productResult?.products ?? []"
      :key="product.id"
      :data-testid="`${productType}-result-row`"
      class="c-search-description flex items-center hover:bg-primary-50 cursor-pointer"
    >
      <div class="min-w-12 max-w-12 p-2">
        <img v-if="product.productImages.length > 0" :src="resizeImage(product.productImages[0].url, 24, 24)" />
        <img v-else src="@/assets/images/no-image.jpg" alt="No image found" class="c-no-search-image" />
      </div>

      <div @click="$emit('goToPage', product.id, false)">
        <div class="truncate text-zinc-800 dark:text-stone-500 text-lg">{{ product.name }}</div>
        <div class="truncate text-zinc-500 dark:text-stone-600 text-sm">{{ product.productNumber }}</div>
      </div>

      <div class="c-link-folder w-full flex justify-end invisible">
        <div class="py-4 px-6 hover:bg-primary-200" @click="$emit('goToPage', product.id, true)">
          <i class="pi pi-arrow-up-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ProductSearchResponse } from "../models/product/ProductSearchResponse";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { useImageService } from "../api/image/ImageService";

const props = defineProps<{
  productResult: ProductSearchResponse | null;
  supplierResult: SupplierSearchResponse | null;
  productType: "product" | "inventory";
}>();

defineEmits<{ (event: "goToPage", id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
const { resizeImage } = useImageService();
</script>

<style lang="scss" scoped>
.c-search-description:hover .c-link-folder {
  visibility: visible;
}
</style>

<template>
  <CustomToast />
  <PrimeVueLocaleHandler />
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.fullPath" />
  </router-view>
  <DevOverlay v-if="isStandalone && !isRunningTests" />
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted, ref } from "vue";
import { CustomToast } from "@cumulus/toast";
import { navigationShortcut } from "@cumulus/shortcut";
import { useAuth } from "@cumulus/event-bus";
import { DevOverlay } from "@cumulus/components";

navigationShortcut();

const isStandalone = ref(import.meta.env.VITE_APP_STANDALONE === "true");
const isRunningTests = ref(import.meta.env.VITE_APP_TESTS === "true");

if (isStandalone.value) {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>

<style lang="scss">
/* Center content */
.c-layout-content {
  height: 100%;
  width: 100%;
  /* overflow: auto; //Bug - Do not add, it breaks sticky items */
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.c-main-container {
  width: 100%;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  height: 100%;
}

@media (min-width: 992px) {
  .c-auth .c-main-container {
    padding-left: 240px;
  }
}

.c-guest .c-main-container {
  padding-left: 0;
}

.c-auth .c-main-container {
  padding-top: 5rem;
}

.c-layout-main-container {
  min-height: 100%;
}

.c-sidebar-mini .c-main-container {
  padding-left: 0;
}

@media (min-width: 992px) {
  .c-sidebar-mini .c-main-container {
    padding-left: 65px;
  }
}

.c-sidebar-show .c-main-container {
  transform: translate3d(280px, 0, 0);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>

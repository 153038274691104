<template>
  <CustomToast />
  <PrimeVueLocaleHandler />
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.fullPath" />
  </router-view>
  <DevOverlay v-if="isStandalone && !isRunningTests" />
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted, ref } from "vue";
import { CustomToast } from "@cumulus/components";
import { navigationShortcut } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";
import { DevOverlay } from "@cumulus/components";

navigationShortcut();

const isStandalone = ref(import.meta.env.VITE_APP_STANDALONE === "true");
const isRunningTests = ref(import.meta.env.VITE_APP_TESTS === "true");

if (isStandalone.value) {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>

<style lang="scss">
.c-layout-content {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
}

.c-main-container {
  width: 100%;
}

.c-auth .c-main-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 56px;
}

@media (min-width: 992px) {
  .c-auth .c-main-container {
    padding-left: 200px;
  }
  .c-auth.c-menu-compact .c-main-container {
    padding-left: 160px;
  }

  .c-auth .c-layout-content {
    width: calc(100% - 200px);
  }

  .c-auth.c-menu-compact .c-layout-content {
    width: calc(100% - 160px);
  }

  .c-auth.c-sidebar-mini.c-menu-compact .c-layout-content {
    width: calc(100% - 65px);
  }
}

.c-guest .c-main-container {
  padding-left: 0;
}

.c-sidebar-mini .c-main-container {
  padding-left: 0;
}

@media (min-width: 992px) {
  .c-sidebar-mini .c-main-container {
    padding-left: 65px;
  }

  .c-sidebar-mini .c-layout-content {
    width: calc(100% - 65px);
  }
}
</style>

<template>
  <div>Sign out is running!</div>
</template>

<script setup lang="ts">
import { useAuth } from "@cumulus/event-bus";
import { onMounted } from "vue";

const { logout } = useAuth();
onMounted(async () => await logout());
</script>

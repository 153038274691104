<template>
  <div
    class="c-header flex justify-between px-2 py-0.5 bg-white dark:bg-stone-900"
    :class="{ 'c-header-full-logo': isMobile && route.path === '/' }"
    data-testid="header-signed-in"
  >
    <div class="flex items-center" :class="{ 'col-span-12 justify-content-center': isMobile && route.path === '/' }">
      <router-link to="/" class="c-logo">
        <picture v-if="theme == 'dark'" class="opacity-30">
          <source srcset="@/assets/images/logo-dark.png" />
          <img src="@/assets/images/logo-dark-small.png" alt="Multicase Portal" />
        </picture>

        <picture v-else>
          <source srcset="@/assets/images/logo.png" />
          <img src="@/assets/images/logo-small.png" alt="Multicase Portal" />
        </picture>
      </router-link>
      <Button
        v-show="!isMobile"
        :icon="showSidebarMini ? 'pi pi-bars' : 'pi pi-align-right'"
        class="p-button-rounded p-button-text c-button-min ml-9 focus:!shadow-none"
        pt:icon:class="!text-2xl text-zinc-800 dark:text-stone-400 dark:bg-stone-900"
        @click="emit('onToggleSidebarMini')"
      ></Button>

      <div v-if="currentContext" class="pl-8">
        <h1 class="dark:text-stone-400">{{ t(`header.title.${currentContext}`) }}</h1>
      </div>
      <div v-show="!isMobile" id="c-navbar-title" class="c-hide-in-navbar pl-8"></div>
    </div>

    <div class="flex items-center">
      <Search :is-mobile="isMobile" />
    </div>

    <div v-if="!isMobile" class="flex justify-end items-center">
      <div v-show="!isMobile" id="c-navbar-shortcuts" class="c-hide-in-navbar"></div>

      <UserProfile v-show="showUserProfile" @theme-changed="loadTheme" />
      <Alerts v-show="showUserProfile" />

      <Button
        v-show="isMobile"
        :icon="showMobileMenu ? 'pi pi-times' : 'pi pi-bars'"
        class="p-button-rounded p-button-text c-mobile-menu"
        @click="emit('onToggleMobileMenu')"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Search from "@/views/auth/search/components/Search.vue";
import Alerts from "./Alerts.vue";
import UserProfile from "./UserProfile.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

defineProps<{
  isMobile: boolean;
  showSidebarMini: boolean;
  showMobileMenu: boolean;
}>();

const emit = defineEmits<{
  (e: "onToggleSidebarMini"): void;
  (e: "onToggleMobileMenu"): void;
}>();

const showUserProfile = ref<boolean>(true);
const route = useRoute();
const { t } = useI18n();

const contexts = [
  { path: "/purchase", key: "purchase" },
  { path: "/customer", key: "customer" },
  { path: "/goods-reception", key: "goods-reception" },
  { path: "/order", key: "order" },
  { path: "/price/discount", key: "discounts" },
  { path: "/product", key: "product" },
  { path: "/supplier", key: "supplier" },
  { path: "/warehouse/inventory", key: "inventory" },
  { path: "/warehouse/goods-out/order-ready-for-picking", key: "order-ready-for-picking" },
  { path: "/warehouse/goods-out/open-picking-lists", key: "picking-lists" },
  { path: "/warehouse/goods-out/packing-notes", key: "packing-notes" },
  { path: "/finance/invoice", key: "invoice" },
  { path: "/finance/credit-note", key: "credit-note" },
  { path: "/finance/transfer", key: "transfer" },
  { path: "/finance/settings", key: "settings" },
  { path: "/system-data/company", key: "company-info" },
  { path: "/system-data/clients", key: "clients" },
  { path: "/system-data/employees", key: "employees" },
  { path: "/system-data/settings", key: "settings" },
  { path: "/system-data/printing", key: "printing" },
  { path: "/administration/company", key: "companies" },
];

const showInNavbarOnScroll = () => {
  if (import.meta.env.VITE_APP_STANDALONE === "true") {
    return;
  }
  const shortcutElement = document.getElementById("c-navbar-shortcuts");
  const titleElement = document.getElementById("c-navbar-title");

  if (shortcutElement && titleElement) {
    if (titleElement.children.length === 0 && shortcutElement.children.length === 0) {
      shortcutElement.classList.remove("c-show-in-navbar");
      titleElement.classList.remove("c-show-in-navbar");
      showUserProfile.value = true;
      return;
    }

    const headerOffSet = shortcutElement.offsetTop;
    if (window.pageYOffset > headerOffSet) {
      titleElement.classList.add("c-show-in-navbar");
      shortcutElement.classList.add("c-show-in-navbar");
      showUserProfile.value = false;
    } else {
      shortcutElement.classList.remove("c-show-in-navbar");
      titleElement.classList.remove("c-show-in-navbar");
      showUserProfile.value = true;
    }
  }
};

const currentContext = computed<string>(() => {
  const currentPath = route.path;
  const context = contexts.find((ctx) => currentPath.startsWith(ctx.path));
  return context ? context.key : "";
});

const getTheme = () => {
  return localStorage.getItem("theme");
};

const getMediaPreference = () => {
  const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (hasDarkPreference) {
    return "dark";
  } else {
    return "light";
  }
};

const setTheme = (theme: string) => {
  localStorage.setItem("theme", theme);
  document.documentElement.className = "bg-[#F0F6FA] dark:bg-[#27272A] " + theme;
};

const theme = ref<string | null>(null);

onMounted(() => {
  window.addEventListener("scroll", showInNavbarOnScroll);
  loadTheme();
});

const loadTheme = () => {
  theme.value = getTheme() || getMediaPreference();
  setTheme(theme.value);
};

onUnmounted(() => {
  window.removeEventListener("scroll", showInNavbarOnScroll);
});
</script>

<style lang="scss" scoped>
.c-hide-in-navbar {
  visibility: hidden;
}

.c-show-in-navbar {
  visibility: visible;
}

.c-header {
  height: 56px;

  img {
    @media (max-width: 767px) {
      height: 40px;
      width: 55px;
      object-fit: cover;
      object-position: 0% 25%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
  &.c-header-full-logo {
    height: auto;
    flex-direction: column;
    justify-content: center;
    img {
      width: 172px;
    }
  }
}

.c-use-megamenu .c-header {
  z-index: 997;
}

.c-logo {
  img {
    @media (min-width: 992px) {
      height: 36px;
      width: 152px;
      object-fit: cover;
      object-position: 0% 0%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
}
.c-sidebar-mini .c-logo {
  img {
    @media (min-width: 992px) {
      height: 40px;
      width: 55px;
      object-fit: cover;
      object-position: 0% 25%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
}

.c-nav-tools {
  margin: 0.5rem 1rem 0 0rem;
}
</style>

<style lang="scss">
.c-use-megamenu .p-sidebar-mask {
  z-index: 2;
}
</style>

import { SearchRequest } from "@/views/auth/search/models/search/SearchRequest";
import { searchApi } from "@/views/auth/search/api/search/SearchApi";
import { useI18n } from "vue-i18n";
import { InternalServerError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { CustomerSearchResponse } from "../../models/search/CustomerSearchResponse";

import { useAuth } from "@cumulus/event-bus";
import { useRoute, useRouter } from "vue-router";

import { OrderSearchResponse } from "../../models/order/OrderSearchResponse";
import { SupplierSearchResponse } from "../../models/supplier/SupplierSearchResponse";
import { ProductSearchResponse } from "../../models/product/ProductSearchResponse";
import { PurchaseOrderSearchResponse } from "../../models/purchase/PurchaseOrderSearchResponse";
import { Pages } from "../../models/search/Pages";

const pageSize = 6;

export const useSearch = () => {
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();
  const { getAuthHeaders } = useAuth();
  const router = useRouter();
  const route = useRoute();

  const searchProduct = async (query: string): Promise<ProductSearchResponse> => {
    try {
      const request = new SearchRequest();
      request.pageSize = pageSize;
      request.query = query.replace("\\", "\\\\").replace('"', '\\"');

      const authHeaders = await getAuthHeaders();
      const supplierResponse = await searchApi.searchProduct(authHeaders, request);

      return supplierResponse;
    } catch (error) {
      let errorMessage = t("common.error-detail.unknown");
      if (error instanceof InternalServerError) {
        errorMessage = t("common.error-detail.server-error");
      }

      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });

      throw error;
    }
  };

  const searchCustomer = async (query: string): Promise<CustomerSearchResponse> => {
    try {
      const request = new SearchRequest();
      request.pageSize = pageSize;
      request.query = query.replace("\\", "\\\\").replace('"', '\\"');

      const authHeaders = await getAuthHeaders();
      const supplierResponse = await searchApi.customerSearch(authHeaders, request);

      return supplierResponse;
    } catch (error) {
      let errorMessage = t("common.error-detail.unknown");
      if (error instanceof InternalServerError) {
        errorMessage = t("common.error-detail.server-error");
      }

      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });

      throw error;
    }
  };

  const searchOrder = async (query: string): Promise<OrderSearchResponse> => {
    try {
      const request = new SearchRequest();
      request.pageSize = pageSize;
      request.query = query.replace("\\", "\\\\").replace('"', '\\"');

      const authHeaders = await getAuthHeaders();
      const response = await searchApi.orderSearch(authHeaders, request);

      return response;
    } catch (error) {
      let errorMessage = t("common.error-detail.unknown");
      if (error instanceof InternalServerError) {
        errorMessage = t("common.error-detail.server-error");
      }

      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });

      throw error;
    }
  };

  const searchSupplier = async (query: string): Promise<SupplierSearchResponse> => {
    try {
      const request = new SearchRequest();
      request.pageSize = pageSize;
      request.query = query.replace("\\", "\\\\").replace('"', '\\"');

      const authHeaders = await getAuthHeaders();
      const response = await searchApi.supplierSearch(authHeaders, request);

      return response;
    } catch (error) {
      let errorMessage = t("common.error-detail.unknown");
      if (error instanceof InternalServerError) {
        errorMessage = t("common.error-detail.server-error");
      }

      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });

      throw error;
    }
  };

  const searchPurchaseOrder = async (query: string): Promise<PurchaseOrderSearchResponse> => {
    try {
      const request = new SearchRequest();
      request.pageSize = pageSize;
      request.query = query.replace("\\", "\\\\").replace('"', '\\"');

      const authHeaders = await getAuthHeaders();
      const response = await searchApi.purchaseOrderSearch(authHeaders, request);

      return response;
    } catch (error) {
      let errorMessage = t("common.error-detail.unknown");
      if (error instanceof InternalServerError) {
        errorMessage = t("common.error-detail.server-error");
      }

      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });

      throw error;
    }
  };

  const goToSearchResult = (pages: Pages, search: string, id?: string): void => {
    // Make a clean copy of the current query
    const queries = JSON.parse(JSON.stringify(route.query));
    queries.search = encodeURIComponent(search);
    delete queries.focus;

    router.replace({
      path: `/${pages}/search` + (id !== undefined ? `/${id}` : ""),
      query: id ? { ...queries, modal: "view" } : queries,
    });
  };

  const searchKeyFromUrl = (): string => {
    let search = "";
    const queries = route.query.search as string;
    if (queries !== undefined) {
      search = decodeURIComponent(queries);
    }
    return search;
  };

  const currentSearchContext = (): Pages => {
    if (route.path.indexOf("product") !== -1) return Pages.Product;
    if (route.path.indexOf("customer") !== -1) return Pages.Customer;
    if (route.path.indexOf("order") !== -1) return Pages.Order;
    if (route.path.indexOf("supplier") !== -1) return Pages.Supplier;
    if (route.path.indexOf("purchase") !== -1) return Pages.PurchaseOrder;
    if (route.path.indexOf("goods-reception") !== -1) return Pages.GoodsReception;
    if (route.path.indexOf("inventory") !== -1) return Pages.Inventory;

    return Pages.Default;
  };

  const isSearchResultPage = (page: Pages): boolean => {
    const val = "/" + page + "/search";
    return route.path.toLocaleLowerCase().indexOf(val) !== -1;
  };

  const focusSearchInput = (): boolean => {
    return route.query?.focusResult === undefined;
  };

  return {
    searchProduct,
    searchCustomer,
    searchOrder,
    searchSupplier,
    searchPurchaseOrder,
    goToSearchResult,
    searchKeyFromUrl,
    currentSearchContext,
    isSearchResultPage,
    focusSearchInput,
  };
};

export default {
  tab: {
    borderWidth: "0px 0px 0px 0px",
    padding: "1.125rem 1.125rem 0.325rem 1.125rem",
    fontWeight: "normal",
  },
  activeBarHeight: "2px",
  activeBarBottom: "10px",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  css: () => `
      .c-toolbar-tab.p-tabs .c-toolbar-wrapper {
        top: 48px;
        margin-left: -16px;
        padding-left: 0;
        background-color: #f0f6fa;
      }

      .c-main-container .c-toolbar-tab.p-tabs .c-toolbar {
        top: 48px;
      }

      .c-toolbar-tab.p-tabs .p-tablist {
        position: fixed;
        top: 119px;
        width: 100%;
        z-index: 1;
      }

      .c-toolbar-tab.p-tabs .p-tablist-tab-list {
        padding-left: 0.5rem;
        background: #f0f6fa;
        border-bottom: none;  
        padding-bottom: 1rem;
      }

      .c-toolbar-tab.p-tabs .p-tabpanels {
        margin-top: 47px;
        background: transparent;
      }
      `,
};

<template>
  <div class="flex justify-between">
    <p class="font-semibold text-lg text-zinc-800 dark:text-stone-400">
      {{ t("search.supplier", 2) }} ({{ supplierResult?.totalHits }})
    </p>
    <Button
      :label="t('search.show-all')"
      class="p-button-link"
      data-testid="supplier-show-all"
      @click="$emit('goToPage', Pages.Supplier, null, false)"
    ></Button>
  </div>
  <div
    v-for="supplier in supplierResult?.suppliers ?? []"
    :key="supplier.id"
    data-testid="supplier-result-row"
    class="c-search-description flex items-center hover:bg-primary-50 cursor-pointer"
  >
    <div class="min-w-12 max-w-12 p-2">
      <i class="pi pi-car text-orange-500"></i>
    </div>

    <div @click="$emit('goToPage', Pages.Supplier, supplier.id, false)">
      <div class="truncate text-zinc-800 dark:text-stone-500 text-lg">{{ supplier.name }}</div>
      <div class="truncate text-zinc-500 dark:text-stone-600 text-sm">{{ supplier.supplierNumber }}</div>
    </div>

    <div class="c-link-folder w-full flex justify-end invisible">
      <div class="py-4 px-6 hover:bg-primary-200" @click="$emit('goToPage', Pages.Supplier, supplier.id, true)">
        <i class="pi pi-arrow-up-right"></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { Pages } from "../models/search/Pages";

defineProps<{ supplierResult: SupplierSearchResponse | null }>();
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.c-search-description:hover .c-link-folder {
  visibility: visible;
}
</style>
